import logo from '../assets/logo.png';
import { Link } from "react-router-dom"

export function Navbar() {

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <div className="h-[75px] max-w-full overflow-hidden lg:h-[100px] bg-violet-400 flex flex-row justify-between opacity-100 lg:sticky top-0 z-50">
            <div className="logo hidden h-full w-1/3 bg-red-0 lg:flex items-center justify-center">
                <Link to="/" onClick={scrollToTop}>
                    <img src={logo} alt="logo" className="w-[100px] h-auto" />
                </Link>
            </div>
            <div className="company_name hidden lg:flex justify-center items-center h-24 w-1/3 bg-red-0">
                <Link to="/" onClick={scrollToTop}>
                    <h1 className="text-6xl font-title text-violet-500">Satukotiin</h1>
                </Link>
            </div>
            <div className="menu flex justify-center items-center w-full h- lg:w-1/3 my-1">
                <ul className="flex gap-2 justify-center lg:flex-none lg:gap-0 w-full h-full lg:w lg:h">
                    <li>
                        <Link to="/" onClick={scrollToTop}>
                            <button className="font-bold w-full h-full lg:text-xl hover:brightness-110 hover:text-[110%]  xl:hover:text-[23px] hover:bg-violet-400 lg:h-24 lg:w-36 hover:text-violet-100 bg-violet-300 lg:bg-opacity-0 px-2 lg:px-0 rounded lg:rounded-none">Etusivu</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/services" onClick={scrollToTop}>
                            <button className="font-bold w-full h-full lg:text-xl hover:brightness-110 hover:text-[110%]  xl:hover:text-[23px] hover:bg-violet-400 lg:h-24 lg:w-36 hover:text-violet-100 bg-violet-300 lg:bg-opacity-0 px-2 lg:px-0 rounded lg:rounded-none">Palvelut</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/prices" onClick={scrollToTop}>
                            <button className="font-bold w-full h-full lg:text-xl hover:brightness-110 hover:text-[110%]  xl:hover:text-[23px] hover:bg-violet-400 lg:h-24 lg:w-36 hover:text-violet-100 bg-violet-300 lg:bg-opacity-0 px-2 lg:px-0 rounded lg:rounded-none">Hinnasto</button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" onClick={scrollToTop}>
                            <button className="font-bold w-full h-full lg:text-xl hover:brightness-110 hover:text-[110%]  xl:hover:text-[23px] hover:bg-violet-400 lg:h-24 lg:w-[160px] hover:text-violet-100 bg-violet-300 lg:bg-opacity-0 px-2 lg:px-0 rounded lg:rounded-none">Yhteystiedot</button>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}