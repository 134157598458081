import MainImage from "../assets/main_page.jpg";
import FlowerImage from "../assets/flower.jpg"
import Joulu from "../assets/joulu.jpg"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet-async";

export function Main(props) {

    function handleClick() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <>
            <Helmet>
                <title>Satukotiin</title>
                <meta name="description" content="Satukotiin tarjoaa luotettavaa ja turvallista kotipalvelua ikääntyneille Nurmijärven alueella arki-iltaisin ja viikonloppuisin." />
                <meta name="keywords" content="Satukotiin, kotipalvelu, ikäihmiset, Nurmijärvi, kotiapu, asiointiapu, saattopalvelu, ulkoilu, ruuanvalmistus, vaatehuolto, ylläpitosiivous, sosiaalinen kanssakäyminen, perhehoito, ennakkovalmennus, sijaisuudet, yksityiset palveluyritykset, Keski-Uudenmaan sote" />
                <link rel="canonical" href="/" />
            </Helmet>

            <div className="relative lg:w-full lg:full">
                <div className="bg-hero -z-30 bg-no-repeat bg-cover bg-fixed w-full h-screen brightness-[80%] absolute">
                </div>
                <div className="title lg:w-full lg:h-[900px] bg-red-0 flex justify-center items-center lg:items-end lg:pb-[300px]">
                    <h1 className="text-white w-3/4 font-bold bg-orange-0 py-10 px-5 lg:p-0 text-5xl overflow-hidden lg:text-8xl antialiased italic bg-orange-0">
                        Apua, tukea ja seuraa ikäihmisille
                    </h1>
                </div>
                <div className="main-content w-full h-[2100px] flex justify-center bg-violet-400 lg:w-full lg:h-[2000px] xl:h-[1450px] xl:bg-flower bg-top-left bg-no-repeat relative overflow-hidden">
                    <div className="text bg-red-0 w-[350px] lg:w-2/4 absolute lg:left-24 top-[120px] flex flex-col bg-white bg-opacity-30 rounded-3xl px-4 py-10 lg:p-10 text-3xl lg:text-4xl">
                        <p className="pb-10 lg:p-0">Satukotiin tarjoaa luotettavaa ja turvallista kotipalvelua ikääntyneille Nurmijärven alueella arki-iltaisin ja viikonloppuisin.
                        </p>
                        <br />
                        <p className="pb-10 lg:p-0">Kotipalvelu on ei-sairaanhoidollista palvelua, kuten apua ruuanvalmistuksessa, vaatehuollossa ja pienessä ylläpitosiivouksessa. Kotipalvelu auttaa myös sosiaalista kanssakäymistä edistävissä palveluissa, joita ovat esimerkiksi asiointiapu, saattopalvelu ja ulkoilussa avustaminen.</p>
                        <br />
                        <p className="pb-10 lg:p-0">Olen käynyt ikäihmisten perhehoidon ennakkovalmennuksen v. 2017 ja tehnyt sijaisuuksia perhekoteihin.</p>
                        <br />
                        <p className=" font-bold italic pb-10 lg:p-0">Ota rohkeasti yhteyttä joko soittamalla tai lähettämällä sähköpostia.</p>
                        <br />
                        <p className="font-bold pt-5 text-4xl pb-10 lg:p-0">
                            *Kuulun Keski-Uudenmaan soten yksityisiin palveluyrityksiin, jotka tuottavat kotiapua Nurmijärvellä.
                        </p>
                        <Link to="/contact" className="bg-orange-0 w-min h-min">
                            <button className="bg-violet-400 border-violet-400 border-[10px] rounded-xl text-4xl lg:text-5xl p-2 lg:p-5 hover:brightness-[110%] hover:border-violet-500 w-[300px] lg:w-[400px] mx-auto mt-10 text-slate-100 hover:text-white" onClick={handleClick}>Ota yhteyttä</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}