import { Helmet } from 'react-helmet-async';


export function Prices() {
    return (
        <>

            <Helmet>
                <title>Satukotiin | Hinnasto</title>
                <meta name="description" content="Satukotiin hinnasto" />
                <link rel="canonical" href="/prices" />
            </Helmet>

            <div className="relative w-full lg:h-screen flex items-center justify-center">
                <div className="bg absolute bg-prices bg-no-repeat bg-cover w-full h-full -z-30 brightness-[85%] bg-fixed"></div>
                <div className="content bg-white bg-opacity-70 rounded-xl p-10 text-2xl w-[350px] my-10 lg:mt-0 lg:w-[1200px] leading-10 font-serif">
                    <p className="py-2">Arvonlisäveroton (alv. 0%) hintani on <span className="font-semibold">45€/tunti</span>.</p>
                    <p className="py-2">Kuulun ennakkoperintärekisteriin ja palveluistani voi hakea kotitalousvähennystä. Lisätietoa <a href="https://www.vero.fi/henkiloasiakkaat/verokortti-ja-veroilmoitus/vahennykset/kotitalousvahennys/" className="font-semibold text-blue-600 hover:text-blue-700" target="_blank">vero.fi</a>.</p>
                    <p className="py-2">Kartoituskäynti asiakkaan luona on <span className="font-bold">maksuton</span>.</p>
                    <p className="py-2">Veloitan kilometrikorvauksen 0.50€/km, kun sovittu palvelu sisältää asiakkaan kuljetusta tai asiakkaan asioiden hoitoa autoa käyttäen.</p>
                </div>
            </div>
        </>
    )
}