export function Footer(){
    return (
        <div className="w-full bg-violet-400">
            <div className="content w-full mx-auto bg-red-0 flex flex-col items-center py-20 text-4xl text-violet-100">
                <h2 className="text-6xl mb-5 font-title text-violet-500">Satukotiin</h2>
                <p className="">Satu Viik</p>
                <p>puh. 0400 686430</p>
                <p><a href="mailto:satu@satukotiin.fi">satu@satukotiin.fi</a></p>
                <p>Y-tunnus 3194019-3</p>
            </div>
            <div className="rights w-full h-12 bg-violet-600 flex justify-center items-center">
                <p>© 2024 Satukotiin</p>
            </div>
        </div>
    );
}