import { Main } from './pages/main';
import { Prices } from './pages/prices';
import { Services } from './pages/services';
import { Contact } from './pages/contact';
import { Navbar } from './components/navbar';
import { Footer } from './components/footer';
import { Route, Routes } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import { NotFound } from './pages/not-found';


function App() {

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/prices" element={<Prices />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <Analytics />
    </div>
  );
}

export default App;
