import service_image from '../assets/services.jpg'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async';

export function Services() {
    return (
        <>
            <Helmet>
                <title>Satukotiin | Palvelut</title>
                <meta name="description" content="Satukotiin palvelut" />
                <link rel="canonical" href="/services" />
            </Helmet>

            <div className="flex justify-center relative">
                <div className="bg-image bg-services bg-no-repeat bg-cover w-full h-full absolute -z-30 brightness-[87%] bg-fixed">
                </div>
                <div className="contentArea flex gap-14 justify-center bg-yellow-0 w-[350px] lg:w-[1200px] my-24 bg-white bg-opacity-[60%] rounded-3xl px-10 py-10 bg-orange-0">
                    <div className="text text-[25px] leading-8 font-serif bg-orange-0 w-full">
                        <h1 className='font-bold text-5xl mb-[50px]w-[250px] bg-red-0 mx-auto pb-7'>Palvelut</h1>
                        <p>Tarjoan ikäihmisille luotettavaa ja turvallista apua, tukea ja seuraa arjen haasteisiin. Tulen avuksi kotiin tai seuraksi hoivakotiin.</p>
                        <br />
                        <p className='mb-10'>Tavoitteeni on tukea ikäihmisten kotona asumista ja tarjota yksilöllisiä palveluita asiakkaan toiveiden ja tarpeiden mukaan. Tarjoan palveluitani myös hoivakodissa asuville henkilöille.</p>
                        <br />
                        <p className="mb-4 font-bold">Kotipalvelu voi sisältää esimerkiksi</p>
                        <ul className=" list-disc list-inside pl-5">
                            <li>Ruoanlaitto</li>
                            <li>Saattajapalvelu</li>
                            <li>Kauppa- ja muut asiointipalvelut</li>
                            <li>Vaatehuolto</li>
                            <li>Pientä siivousta</li>
                            <li>Sosiaalista kanssakäymistä edistävää toimintaa</li>
                            <li>Ulkoilussa avustamista</li>
                        </ul>
                        <br />
                        <p className="font-bold">Palvelut eivät sisällä sairaanhoidollisia palveluja.</p>
                        <br />
                        <p>Jokaisen  asiakkaan kanssa tehdään yksilöllinen palvelusopimus, jossa sovitaan työn sisällöstä, käytettävästä ajasta ja käyntien tiheydestä. Yhdessä keskustellen löydämme parhaan mahdollisen ratkaisun.</p>
                        <br />
                        <p>Voin tarjota palveluitani ilman arvonlisäveroa silloin kun asiakkaan toimintakyky on heikentynyt esimerkiksi iän tai sairauden takia. Tämä edellyttää palvelusopimuksen ja -suunnitelman tekemistä.</p>
                        <br />
                        <p className='font-bold'>Ota rohkeasti yhteyttä, niin keskustellaan lisää ja sovitaan ilmainen tutustumiskäynti. </p>
                        <Link to="/contact" className='flex items-center'>
                            <button className="bg-violet-400 border-violet-400 border-[10px] rounded-xl text-5xl p-5 hover:brightness-[110%] hover:border-violet-500 w-[400px] mx-auto mt-14 text-slate-100 hover:text-white">Ota yhteyttä</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}