export function NotFound() {
    return (
        <>
            {true && <div className="background w-full absolute -z-50 bg-maincolor opacity-70 h-[1000px]"></div>}
            <div className="text-3xl font-bold text-customfontcolor text-center w-full h-full mt-32 mb-72">
                <h1 className="text-[100px]">404</h1>
                <br />
                <p>Sivua ei löytynyt</p>
            </div>
        </>
    )
}