import satu from '../assets/viik_satu.jpg'
import phoneImage from '../assets/Phone_fill@3x.png'
import messageImage from '../assets/Message_alt_fill@3x.png'
import emailjs from '@emailjs/browser';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';

import { useState, useRef } from 'react'


export function Contact() {
  const [cookies, setCookie, removeCookie] = useCookies(['emailAmount']);
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  if (cookies.emailAmount === undefined | cookies.emailAmount === null) {
    setCookie('emailAmount', 0, { path: '/' }, { maxAge: 60 * 60 * 1 });
  }
  let emailsAmount = parseInt(cookies.emailAmount);

  const form1 = useRef();

  const sendEmail = (message) => {
    emailjs.sendForm('service_qx5qm44', 'template_mfskwmm', message, 'wD7-KbeAxhrPBOjUx')
      .then((result) => {
        console.log(result.text);
        alert("Viestisi on lähetetty onnistuneesti!")
        form1.current.reset();
        setName('')
        setPhone('')
        setEmail('')
        setMessage('')
      }, (error) => {
        console.log(error.text);
        alert("Viestisi lähetys epäonnistui, yritä uudelleen.")
      });
  };

  function onSubmit() {

    if (cookies.emailAmount >= 3) {
      return alert("Maksimi sähköpostien määrä on 3")
    }

    sendEmail(form1.current)

    emailsAmount += 1;
    setCookie('emailAmount', emailsAmount, { path: '/' });
  }

  function handleChange(value) {
    console.log("Captcha value:", value);
  }

  return (

    <>
      <Helmet>
        <title>Satukotiin - Ota yhteyttä</title>
        <meta name="description" content="Ota yhteyttä Satukotiin. Satu Viik, puh. 0400 686430, satu@satukotiin.fi" />
        <link rel="canonical" href="/contact" />
      </Helmet>

      <div className="flex justify-evenly bg-violet-200">
        {/* <div className="contactForm bg-red-0 my-24">
        <form action="" className="flex flex-col w-[400px] gap-2 text-xl bg-violet-300 p-4 rounded-2xl" ref={form1}>
          <label htmlFor="name">Nimi</label>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Etunimi Sukunimi"
            className="py-1 px-2 rounded"
          />
          <label htmlFor="phone">Puhelinnumero</label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Nro."
            className="py-1 px-2 rounded"
          />
          <label htmlFor="email">Sähköposti</label>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="py-1 px-2 rounded"
          />
          <label htmlFor="message">Viesti</label>
          <textarea
            name="message"
            className=" resize-none py-1 px-2 rounded"
            maxLength="1000"
            placeholder='Kirjoita viestisi tähän'
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            cols="30"
            rows="10"
          ></textarea>
          <button
            type="submit"
            className="px-3 py-2 border-4 border-violet-400 bg-violet-400 my-3 font-bold text-serif text-2xl rounded-xl hover:brightness-110 hover:border-violet-500 hover:text-violet-100"
            onClick={onSubmit}
          >Lähetä</button>
        </form>
      </div> */}

        <div className="employee bg-blue-0 w-[320px] my-24 text-xl font-serif leading-10 bg-violet-400 bg-opacity-100 rounded-2xl p-4 relative overflow-hidden">
          <img src={satu} className="w-full rounded-t-[50px]" alt="" />
          <div className="content w-full h- bg-violet-300 py-2 px-2 rounded-b-xl">
            <p className='mt-2 w-full mx-auto flex justify-center rounded font-seriff text-4xl mb-2 bg-white bg-opacity-40 text-violet-700 italic'>Satu Viik</p>
            <div className="line w-full h-[3px] bg-violet-400"></div>
            <p className='mb-4 bg-white bg-opacity-40 mt-3 p-3 rounded-xl leading-6 font-seriff'>Ikääntyneen ihmisen kohtaaminen ja mielekkään arjen tukeminen.</p>
            <div className="line w-full h-[3px] bg-violet-400"></div>
            <div className="phone flex gap-2 items-center justify-center opacity-80">
              <img src={phoneImage} className="h-[32px] w-auto" alt="" />
              <p className=''>0400 686430</p>
            </div>
            <div className="message flex gap-2 items-center justify-center opacity-80">
              <img src={messageImage} className="h-[32px] w-auto" alt="" />
              <a href="mailto:satu@satukotiin.fi">
                <p className=''>satu@satukotiin.fi</p>
              </a>
            </div>
            <div className="line w-full h-[3px] bg-violet-400 opacity-80"></div>
            <p className='text-center'>Y-tunnus 3194019-3</p>
          </div>
        </div>
      </div>
    </>


  )
}